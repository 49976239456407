import axios from "axios";
import config  from '../../env.json';
// import { useSelector, useDispatch } from "react-redux";

function getPersonalizationData(callback, token){
  // let {lang}=params;
    try {
      // const token = useSelector((state: any) => state.login.token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token
        axios.get(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation')
        .then(res=>{ 
          ;
          callback(res.data);
        }).catch(()=>{
          ;
          callback([])
        })
    } catch (error) {
      ;
        callback([])
        
    }
}

function getEmailDetails(callback){
    try {
        axios.get(config.REACT_APP_CALENDAR_API_BASE+'/google/token',{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
        
    }
}

function updatePersonalizationData(payload,callback){
  
  try {
      axios.post(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation',payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}



export {getPersonalizationData,updatePersonalizationData, getEmailDetails}