import React, { useState, useEffect, useCallback } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import './EditContact.css';
// import ReactAutocomplete from "react-autocomplete";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import { useDebounce } from 'usehooks-ts'
import Chip from '@mui/material/Chip';
import axios from "axios";
import config from '../../env.json'
import "./AddContact.css";
import { editContact, addNewContact, checkNumberExist } from "../../apis/contacts/contactsApi";
import ContactAlreadyAssign from '../../components/logoutModal/LogoutModal'

import { parsePhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { setChatDetails, setRefreshChatList, setChatDetailsWOShowChat } from "../../app/slice/chatSlice";
import { setShowSuccessSnackBar, setShowErrorSnackBar } from '../../app/slice/personalisationSlice';
import { getDeviceType } from '../../utils/deviceTypeUtil';
import { pickContact } from "../../utils/phoneNumberUtil";
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";
import { isValid } from "../../utils/CheckValidString";
import getErrorMessage from "../.././utils/getErrorCodeFromJsonFile";
import { Keyboard } from '@capacitor/keyboard';

export default function EditContact({ show, handleCloseParent, contact, onChangefunction, editted }) {
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [assoPresc, setAssoPresc] = useState<any>({})
  const [tempAsso, setTempAsso] = useState('');
  const [post, setPost] = useState([]);
  const [IsPrescriber, setIsPrescriber] = useState(false)
  const [disabled, setDisabled] = useState(true);
  const [prescriberId, setPrescriberId] = useState(0);
  const [changeAssoInput, setChangeAssoInput] = useState(false);
  const debouncedValue = useDebounce<string>(assoPrescName, 350);
  const [typing, setTyping] = useState(false);
  const [secPrescribers, setSecPrescribers] = useState<any>([]);
  const [modalHeight, setModalHeight] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [mutliAssociatePrescriberCheck, setMutliAssociatePrescriberCheck] = useState<any>(true);
  const { chatId, chatName, type, chatIsPrescriber } = useSelector((state: any) => state.chat);

  // State for multi contact select
  const [multiContact, setMultiContact] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState('');
  const [overrideMessage, setOverrideMessage] = useState('');
  const [validation,setValidation]=useState(false)
  const [intialState,setIntialstate]=useState(true)
  const [isNumberLandline, setIsNumberLandline] = useState(false);
  const [isValidString,setIsValidString]=useState(false);
  const [errorData, setErrorData] = useState('');
  const [initiall,setInitiall]=useState(true);
  const [address, setAddress] = useState("")
  const [speciality, setSpeciality] = useState("")
  const dispatch = useDispatch()
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);
  useEffect(() => {
    setErrorData("*"+t(getErrorMessage('NameError')))
    setCountryList()
  },[]);
  const setCountryList = () => {
 let userList: Array<any> = JSON.parse(config.REACT_APP_TEST_USER_LIST);
    let countryTestUserCountryList:Array<any>= config.REACT_APP_TEST_USER_COUNTRY_LIST?.split(",")?.map((code: string) =>
    code?.toLowerCase()
  )
    userList?.forEach((userId) => {
      if (userId === repId) {
        setCountries(countryTestUserCountryList);
      }
    });
  };
  useEffect(() => {
    window.addEventListener("orientationchange", calcHeightWidth);
    return () => {
      window.removeEventListener('orientationchange', calcHeightWidth)
    }
  }, [])

  useEffect(() => {
    if (config.REACT_APP_MULTI_PRES_ASSOCIATION) {
      setMutliAssociatePrescriberCheck(config.REACT_APP_MULTI_PRES_ASSOCIATION === "N" ? false : true)

    }
    calcHeightWidth();
    window.addEventListener('resize', (e) => {
      calcHeightWidth()
    })

  },[])

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
  
      Keyboard.addListener('keyboardWillShow', info => {
      });

      Keyboard.addListener('keyboardDidShow', info => {
        calcHeightWidth();
      });
    
      Keyboard.addListener('keyboardWillHide', () => {
      });

      Keyboard.addListener('keyboardDidHide', () => {
        calcHeightWidth();
        console.log("---Keyboard height---");
      });
    }
    calcHeightWidth()
    window.addEventListener("resize", (e) => {calcHeightWidth()})
  }, []);
  const handleOverrideMsg = (accType,accName) => {
    if(config.REACT_APP_PH_OVERRIDE_MSG){
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
        let type = accType === "N" ? "Others" : "Prescriber";
        let name = accName;
        if(type){
          msg = msg.replace("TYPE", type);
          setOverrideMessage(msg);
        }else{
          msg = msg.replace("TYPE -", "");
          setOverrideMessage(msg);
        }
        if(name){
          msg = msg.replace("NAME", name);
          setOverrideMessage(msg);
        }
    }
  } 

  const calcHeightWidth = () => {
    let doc: any = document
    let div: any = document.querySelector('#main')
    if (!div) return
    let divpaddingTop: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

    // console.log("gc modal toppad", divpaddingTop);
    // console.log("gc modal bottompad", divpaddingbottom);
    // console.log("gc modal leftpad", divpaddingleft);
    // console.log("gc modal rightpad", divpaddingright);
    // console.log("gc modal height", window.innerHeight - divpaddingTop - divpaddingbottom);
    // console.log("gc modal width", window.innerWidth - divpaddingleft - divpaddingright);

    let device = getDeviceType()
    if (device == "mobile" || device == "tablet" || config.REACT_APP_IS_CROSS_PLATFORM) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }

  }

  async  function updateContact(){  
    
    let phn:any=parsePhoneNumber(othersPhone);
    console.log(phn)
    let payload:any={
      AcctPh_Nbr:phn.nationalNumber,
      IntlCallPfx_Nbr:phn.countryCallingCode,
      Id:contact.Id,
      Acct_Splty: speciality ? speciality : "",
      Acct_Adr: address ? address : ""
    }
    
    //for others
    if(!IsPrescriber){

      let isValidStringEntered = isValid(othersName);
      let ContactName = othersName.replace(/\s+/g,' ').trim();

      
          if(ContactName && isValidStringEntered)
          {

              payload={
                  AcctPh_Nbr:phn.nationalNumber,
                  AcctFull_Nm:ContactName,
                  Prescriber_Id:contact.Prescribers.map((x:any)=>x.Id),
                  Id:contact.Id,
                  IntlCallPfx_Nbr:phn.countryCallingCode,
                  Acct_Splty: speciality,
                  Acct_Adr: address
                }  
                console.log(secPrescribers)
                
                if(getNumberFromItem(contact)!==othersPhone){
                  checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
                    console.log(res)  
                    if(res)  {
                      handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
                      setShowModal(true)
                          }  else{
                            addNewContact(payload,async (cont)=>{
                              if(cont){
                                
                                if(cont.Id==chatId && chatIsPrescriber==(cont.Is_Prescriber=="Y")){
                                  // dispatch(setChatDetails(
                                  //   {
                                  //     chatId:cont.Id,
                                  //     chatIsPrescriber:cont.Is_Prescriber=="Y",
                                  //     showChat:true,
                                  //     chatName:cont.AcctFull_Nm
                                  //   }))
                                  dispatch(setChatDetailsWOShowChat({
                                    chatId:cont.Id,
                                      chatIsPrescriber:cont.Is_Prescriber=="Y",
                                      chatName:cont.AcctFull_Nm
                                  }))
                                }
                                dispatch(setRefreshChatList(true))
                  
                                          payload.Prescribers=secPrescribers;
                                          payload.Is_Prescriber="N";
                                          
                                          let done=false;
                                          let original=contact.Prescribers;
                                          let modified=secPrescribers;
                                          let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                                          let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                                          let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                                          let a,d;
                                          if(forAdd.length>0){
                                            let assoPayload={
                                              PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                                              PresciberAccociatesPrescrber_Id: forAdd,
                                            }
                                            // if(mutliAssociatePrescriberCheck)
                                            await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                                              a=res
                                            }).catch(()=>{
                                              
                                            });
                                          }
                                          if(forDelete.length>0){
                                            let assoPayload={
                                              Ids:forDelete
                                            }
                                            await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                                              d= res
                                            }).catch(()=>{
                                              
                                            })
                                          }
                                          let final=payload;
                                            console.log(final);
                                          
                                          clearAll(true,final);
                                          dispatch(setShowSuccessSnackBar(true));
                              }
                              else{
                                clearAll(false,{})
                                dispatch(setShowErrorSnackBar(true));
                              }
                              editted()
                            })
                          }})
                }
                else{
                  addNewContact(payload,async (cont)=>{
                    if(cont){
                      
                      if(cont.Id==chatId && chatIsPrescriber==(cont.Is_Prescriber=="Y")){
                        // dispatch(setChatDetails(
                        //   {
                        //     chatId:cont.Id,
                        //     chatIsPrescriber:cont.Is_Prescriber=="Y",
                        //     showChat:true,
                        //     chatName:cont.AcctFull_Nm
                        //   }))
                        dispatch(setChatDetailsWOShowChat({
                          chatId:cont.Id,
                            chatIsPrescriber:cont.Is_Prescriber=="Y",
                            chatName:cont.AcctFull_Nm
                        }))
                      }
                      dispatch(setRefreshChatList(true))
        
                                payload.Prescribers=secPrescribers;
                                payload.Is_Prescriber="N";
                                
                                let done=false;
                                let original=contact.Prescribers;
                                let modified=secPrescribers;
                                let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                                let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                                let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                                let a,d;
                                if(forAdd.length>0){
                                  let assoPayload={
                                    PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                                    PresciberAccociatesPrescrber_Id: forAdd,
                                  }
                                  // if(mutliAssociatePrescriberCheck)
                                  await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                                    a=res
                                  }).catch(()=>{
                                    
                                  });
                                }
                                if(forDelete.length>0){
                                  let assoPayload={
                                    Ids:forDelete
                                  }
                                  await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                                    d= res
                                  }).catch(()=>{
                                    
                                  })
                                }
                                let final=payload;
                                  console.log(final);
                                
                                clearAll(true,final);
                                dispatch(setShowSuccessSnackBar(true));
                    }
                    else{
                      clearAll(false,{})
                      dispatch(setShowErrorSnackBar(true));
                    }
                  })
                }
          }
          else{
            setIsValidString(!isValidStringEntered);
          }
          
    }
    else{
      if(getNumberFromItem(contact)!==othersPhone){
      checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
        console.log(res)  
        if(res)  {
          handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
          setShowModal(true);
              }  else{
                editContact(payload,(res)=>{
                  if(res){
                    console.log()
                    if(res.Id==chatId && chatIsPrescriber==(res.Is_Prescriber=="Y")){
                    // dispatch(setChatDetails(
                    //   {
                    //     chatId:res.Id,
                    //     chatIsPrescriber:res.Is_Prescriber=="Y",
                    //     showChat:true,
                    //     chatName:res.AcctFull_Nm
                    //   }))
                    dispatch(setChatDetailsWOShowChat({
                      chatId:res.Id,
                        chatIsPrescriber:res.Is_Prescriber=="Y",
                        chatName:res.AcctFull_Nm
                    }))
                    }
                      dispatch(setRefreshChatList(true))
                              
                              // payload.AcctFull_Nm=tempAsso;
                              // payload.Is_Prescriber="Y"
                              
                             clearAll(true,res);
                             dispatch(setShowSuccessSnackBar(true));
                  }
                  else{
                     clearAll(false,{})
                     dispatch(setShowErrorSnackBar(true));
                  }
                  editted()
                })
              }
            })
          }
          else{
            editContact(payload,(res)=>{
              if(res){
                
                if(res.Id==chatId && chatIsPrescriber==(res.Is_Prescriber=="Y")){
                // dispatch(setChatDetails(
                //   {
                //     chatId:res.Id,
                //     chatIsPrescriber:res.Is_Prescriber=="Y",
                //     showChat:true,
                //     chatName:res.AcctFull_Nm
                //   }))
                dispatch(setChatDetailsWOShowChat({
                  chatId:res.Id,
                    chatIsPrescriber:res.Is_Prescriber=="Y",
                    chatName:res.AcctFull_Nm
                }))
                }
                  dispatch(setRefreshChatList(true))
                          
                          // payload.AcctFull_Nm=tempAsso;
                          // payload.Is_Prescriber="Y"
                          
                         clearAll(true,res);
                         dispatch(setShowSuccessSnackBar(true));
              }
              else{
                 clearAll(false,{})
                 dispatch(setShowErrorSnackBar(true));
              }
            })
          }
    }
   
    
    
   
  }

  const handleYOrNPopup = (e: any) => {

    if (e === true) {
      let phn: any = parsePhoneNumber(othersPhone);
      console.log(phn)
      let payload: any = {
        AcctPh_Nbr: phn.nationalNumber,
        IntlCallPfx_Nbr: phn.countryCallingCode,
        Id: contact.Id,
        Acct_Splty: speciality ? speciality : "",
        Acct_Adr: address ? address : ""
      }
      if (!IsPrescriber) {
        payload = {
          AcctPh_Nbr: phn.nationalNumber,
          AcctFull_Nm: othersName,
          Prescriber_Id: contact.Prescribers.map((x: any) => x.Id),
          Id: contact.Id,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Acct_Splty: speciality,
          Acct_Adr: address
        }
        addNewContact(payload, async (cont) => {
          if (cont) {

            if (cont.Id == chatId && chatIsPrescriber == (cont.Is_Prescriber == "Y")) {
              dispatch(setChatDetails(
                {
                  chatId: cont.Id,
                  chatIsPrescriber: cont.Is_Prescriber == "Y",
                  showChat: true,
                  chatName: cont.AcctFull_Nm
                }))
              
            }
            dispatch(setRefreshChatList(true))

            payload.Prescribers = secPrescribers;
            payload.Is_Prescriber = "N";
            
            let done = false;
            let original = contact.Prescribers;
            let modified = secPrescribers;
            let difference = original.filter(x => { if (modified.findIndex(p => p.Id == x.Id) < 0) return x; }).concat(modified.filter(x => { if (original.findIndex(p => p.Id == x.Id) < 0) return x; }))
            let forDelete: any = difference.filter((x: any) => { if (x.Association_Id) return x.Id }).map(x => x.Association_Id)
            let forAdd: any = difference.filter((x: any) => { if (!x.Association_Id) return x.Id }).map(x => x.Id)
            let a, d;
            if (forAdd.length > 0) {
              let assoPayload = {
                PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                PresciberAccociatesPrescrber_Id: forAdd,
              }
              // if(mutliAssociatePrescriberCheck)
              await axios.post(config.REACT_APP_CONTACTS_API_BASE + "/contact/associateassociation", assoPayload).then(res => {
                a = res
              }).catch(() => {

              });
            }
            if (forDelete.length > 0) {
              let assoPayload = {
                Ids: forDelete
              }
              await axios.post(config.REACT_APP_CONTACTS_API_BASE + "/contact/associateassociation/delete", assoPayload).then(res => {
                d = res
              }).catch(() => {

              })
            }
            let final = payload;
            console.log(final);
            
            clearAll(true, final);
            dispatch(setShowSuccessSnackBar(true));
          }
          else {
            clearAll(false, {})
            dispatch(setShowErrorSnackBar(true));
          }
          editted()
        })
      } else {
        editContact(payload, (res) => {
          if (res) {

            if (res.Id == chatId && chatIsPrescriber == (res.Is_Prescriber == "Y")) {
              dispatch(setChatDetails(
                {
                  chatId: res.Id,
                  chatIsPrescriber: res.Is_Prescriber == "Y",
                  showChat: true,
                  chatName: res.AcctFull_Nm
                }))
              
            }
            dispatch(setRefreshChatList(true))
            
            // payload.AcctFull_Nm=tempAsso;
            // payload.Is_Prescriber="Y"
            
            clearAll(true, res);
            dispatch(setShowSuccessSnackBar(true));
          }
          else {
            clearAll(false, {})
            dispatch(setShowErrorSnackBar(true));
          }
        })
      }

    } else {
      setShowModal(false)
    }
  }
  const clearAll = (passOrNot, data) => {
    onChangefunction(true)
    setOthersName('');
    // setOthersPhone('');
    setSecPrescribers([]);
    setDisabled(true)
    setIntialstate(true)
    setChangeAssoInput(false)
    setInitiall(true)
    if (passOrNot) {
      handleCloseParent(true, data);
    } else
      handleCloseParent(false, {})

  }
  const checkDisabled = (name, phone, presList,status) => {
    console.log({name, phone, presList, validation , intialState  ,initiall})
    if (name && phone && presList && !validation && !intialState  && !initiall) {
      setDisabled(false)
    }
    else setDisabled(true);
  }
  useEffect(() => {
    if (contact) {
      setTyping(false);
      setOthersPhone(getNumberFromItem(contact))
      setAddress(contact.Acct_Adr)
      setSpeciality(contact.Acct_Splty)
      if (contact.Is_Prescriber === "N") {
        setOthersName(contact.AcctFull_Nm);
        if (contact.Prescribers) {


          //setTempAsso(contact.Prescriber.AcctFull_Nm);
          setPrescriberId(contact.Prescriber_Id);
          setAssoPresc(contact.Prescribers)
          setSecPrescribers(contact.Prescribers)
          setAssoPrescName(contact.Prescribers)

        }
        setIsPrescriber(false);
        setDisabled(true)
      }
      else {
        setPrescriberId(contact.Id);
        setTempAsso(contact.AcctFull_Nm);
        setIsPrescriber(true);
        setAssoPrescName(contact.AcctFull_Nm)
        setDisabled(false)
      }
    }
  }, [contact, show])
  const getNumberFromItem = (item) => {
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr;
      } else
        return '+' + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
    }
    else return ""
  }
  const checkValidation = (e) => {
    setValidation(e);
    setIntialstate(e);
    if(!initiall){
      setDisabled(e)
    }
    else if (IsPrescriber) {
      checkDisabled(true, true, true,e)
    }
    else {
      checkDisabled(othersName.length > 0, true, secPrescribers.length > 0,e)
    }
  }

  useEffect(() => {
    
    getModalDimensions();
  }, [IsPrescriber])

  const getModalDimensions = () => {
    let div: any = document.querySelector('#main')
    if (!div) return
    let divpaddingTop: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

    let device = getDeviceType()
    if (device == "mobile" || device == "tablet" || config.REACT_APP_IS_CROSS_PLATFORM) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  }

  const onChipDelete = (x) => {
    // if(x.Association_Id){
    //   let c=deleteSecPres.push()
    //   setDeleteSecPres(x);
    // }
    if (changeAssoInput) {
      
      let temp = secPrescribers.filter((p: any) => { if (p.Id !== x.Id) return p; })
      console.log(temp)
      setSecPrescribers(temp);
      checkDisabled(othersName.length > 0, othersPhone.length > 0, temp.length > 0,false)


    }

  }


  const pickcontact = () => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      pickContact().then((resp: any) => {
        if (resp.contact.phones.length > 1) {
          setOthersName(resp.contact.name.display)
          setMultiContact(resp.contact)
          setOpen(true);
        } else {
          setOthersName(resp.contact.name.display)
          setOthersPhone(resp.contact.phones[0].number.split("-").join(" "))
        }
      });
    }
  }
  const setNumberLandline = (e) => {
    setIsNumberLandline(e)
  }

  useEffect(()=>{
    if (!IsPrescriber && (!othersName.length || !assoPrescName.length || !othersPhone.length || secPrescribers.length == 0)){
      setDisabled(true)
    }else if(IsPrescriber && !othersPhone.length){
      setDisabled(true)
    }else{
      setDisabled(false)
    }
  },[othersName,assoPrescName,othersPhone,IsPrescriber])

  // my code ends

  useEffect(()=>{
if (!initiall && (address || speciality)){
  checkDisabled(IsPrescriber || othersName.length > 0, othersPhone,  IsPrescriber || secPrescribers.length > 0, true)
}
  },[address, speciality, initiall])

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        // onClose={handleCloseParent}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
           <div className="modal-box edit-contact-box ipad-top-margin" style={mobileStyle?{height:modalHeight,width:modalWidth}:{}}>
           <div className="modal-header d-flex">
              <h1 className="font-createcontact">{t('Edit Contact')}</h1>
              <span onClick={()=>clearAll(false,{})} className="close">
                ×
              </span>
            </div>
            
            <div className="modal-body editContacts"
            style={mobileStyle?{height:(modalHeight - 99.7)+'px'}:{}}
            >
              <form onSubmit={(e)=>{e.preventDefault();updateContact();}}>
              <div className="form-fields-div">
              {
                    IsPrescriber?null:
                    <div>
                      <div className="d-flex justify-content-between mb-3">
                      <span className="font-md">{t('Recipient Name')+"*"}</span>
                      </div>
                      <TextField
                        className="roundborder"
                        onChange={(e) => {
                          setIsValidString(false)
                          const result = e.target.value.replace(/[^A-Za-z0-9 _]/gi, '');
                          setOthersName(result);
                          setIntialstate(false)
                          checkDisabled(e.target.value.length > 0, othersPhone, secPrescribers.length > 0, true)
                        }}
                        value={othersName}
                        placeholder={t("Please enter name")}
                        color="secondary"
                        focused
                        required
                        inputProps = {{maxLength:config.REACT_APP_CONTACT_NAME_MAXLENGTH}}
                      />
                  {
                     isValidString && <p style={{color:'red',fontSize:'12px',margin:'0',wordWrap: 'break-word'}}>{errorData}</p>
                  }
                    </div>

                }

                <div className="d-flex justify-content-between">
                <span className="font-md">{t('Phone Number')+"*"}</span>
                </div>

                <PhoneNumberDropdown 
                frompage={'EditContact'}
                errorFunc={(e)=>{checkValidation(e)}} 
                disableColored={false} 
                valueNumber={othersPhone} 
                initiall={(e)=>{setInitiall(e)}}
                countryList={countries}
                onChangeNumber={(e)=>{
                  setOthersPhone(e);
                }}
                numberLandline={(e: any) => { setNumberLandline(e) }}
                />

<div>
                      <div className="d-flex justify-content-between mb-3">
                        <span className="font-md">{t("Address")}</span>
                      </div>
                      <TextField
                        className="roundborder inputDisable"
                        onChange={(e) => {
                          setAddress(e.target.value);
                          setInitiall(false)
                          
                        }}
                        disabled
                        value={address ||" "}
                        placeholder={t("Please enter address")}
                        color="secondary"
                        inputProps={{ maxLength: 200 }}
                      />
                    </div>

                    <div>
                      <div className="d-flex justify-content-between mb-3">
                        <span className="font-md">{t("Speciality")}</span>
                      </div>
                      <TextField
                        className="roundborder inputDisable"
                        onChange={(e) => {
                          setSpeciality(e.target.value);
                          setInitiall(false)
                        }}
                        disabled
                        value={speciality ||" "}
                        placeholder={t("Please enter speciality")}
                        color="secondary"
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>



                    <div>{IsPrescriber}</div>
                    {
                      IsPrescriber ?
                        <div className="d-flex justify-content-between">
                          <span className="font-md">{t('Prescriber Name')}</span>
                        </div> :
                        <div className="d-flex justify-content-between">
                          <span className="font-md">{config.REACT_APP_MULTI_PRES_ASSOCIATION === "N" ? t('Prescriber Name')+"*" : t('Associated Prescribers')+"*"}</span>
                          <a className="changeAssociationButton" onClick={() => { changeAssoInput ? setChangeAssoInput(false) : setChangeAssoInput(true) }}>{changeAssoInput ? t("Hide Association") : t("Change Association")}</a>
                        </div>

                    }
                    {
                      IsPrescriber ? <TextField
                        className={`roundborder disabledInput`}
                        value={tempAsso}
                        disabled={IsPrescriber}
                        color="secondary"
                        focused
                        required
                      /> : null
                    }
                    {mutliAssociatePrescriberCheck ?
                      <div>
                        {
                          changeAssoInput ?

                            <div className="secPresDiv">
                              {
                                secPrescribers.map((x: any) => (

                                  <Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm}
                                    onDelete={() => {
                                      onChipDelete(x);
                                    }}
                                  />))
                              }
                            </div> : <div className="secPresDiv">
                              {
                                secPrescribers.map((x: any) => (
                                  <Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm}
                                  />
                                ))
                              }
                            </div>
                        }
                      </div> :
                      <div>
                        {
                          changeAssoInput ?
                            secPrescribers.map((x: any) => {
                              return (
                                <TextField
                                  className={`roundborder disabledInput`}
                                  value={x.AcctFull_Nm}
                                  disabled={IsPrescriber}
                                  color="secondary"
                                  focused
                                  required
                                />
                              )
                            }
                            )

                            :
                            secPrescribers.map((x: any) => {
                              return (
                                <TextField
                                  className={`roundborder disabledInput`}
                                  value={x.AcctFull_Nm}
                                  disabled={IsPrescriber}
                                  color="secondary"
                                  focused
                                  required
                                />
                              )
                            }
                            )
                        }
                      </div>}



                    {
                      changeAssoInput ?
                        <AssociatedPresList
                          clearDataOnSelect={mutliAssociatePrescriberCheck}
                          placeholder={t("Search for an Associated Prescriber")}
                          label=""
                          styleClass=""
                          isclear={true}
                          onChangeInputValue={(e: any) => { console.log(e); setInitiall(false) }}
                          setAssoPresc={(e: any) => {
                            
                            setIntialstate(false)
                            let t: any = [e];
                            if (mutliAssociatePrescriberCheck) {

                              t = t.concat(secPrescribers);
                            }
                            
                            const result: any = [];
                            const map = new Map();
                            for (const item of t) {
                              if (!map.has(item.Id)) {
                                map.set(item.Id, true);    // set any value to Map
                                result.push(item);
                              }
                            }

                            checkDisabled(othersName.length > 0, othersPhone.length > 0, result.length > 0,true)
                            console.log(result)
                            
                            setSecPrescribers(result);
                           
                          }}
                        />
                        //<AssociatedPresList placeholder={"Search for an Associated Prescriber"} label='' styleClass="" setAssoPresc={(e:any)=>{setPrescriber(e)}} />
                        : null
                    }

                    <br />
                    <button type="submit" className="create-btn" disabled={disabled || isNumberLandline  || validation} style={{ opacity: disabled|| isNumberLandline  || validation ? 0.3 : 1 }}>
                      {t('Update')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ContactAlreadyAssign
        show={showModal}
        yorn={handleYOrNPopup}
        modalText={overrideMessage}
        // data={this.state.callDetails}
        handleCloseParent={(e) => {
          console.log(e);
          setShowModal(false)
        }} />
    </div>
  );
}
