import React, { useEffect, useState } from 'react'
import ChatList from '../../components/chat/ChatList'
import ChatComponent from '../../components/chat/ChatComponent'
//import MobileChatComponent from '../../components/chat/MobileChatComponent';
import '../chat/Chat.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from '../../app/slice/sideBarSlice';

import { useTranslation, initReactI18next } from "react-i18next";
import Header from '../../layouts/header/Header';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import  config  from '../../env.json';


function Chat() {
  const { t } = useTranslation();
  const pageTitle=useSelector((state:any)=>state.sideBar.title)
  const dispatch = useDispatch()
  const showChat = useSelector((state: any) => state.chat.showChat);
  // const active = useSelector((state: any) => state.sideBar)
  const [activeChatDetails, setActiveChatDetails] = useState({});
  const [showToggle, setShowToggle] = useState(true);
  const [showActiveChatPage, setShowActiveChatPage] = useState(false);
  const [openNewChat, setOpenNewChat] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [listItemClicked, setListItemClicked] = useState(false);
  const chatData = (data) => {
    console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    setActiveChatDetails(data.data);
    setShowActiveChatPage(data.showChat);
  }
  const newChatData = (data) => {
    console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    // setActiveChatDetails(data.data);
    // setShowActiveChatPage(data.showChat);
  }


  useEffect(() => {

    

    //    dispatch(setActiveTab(1004))
    //dispatch(setActiveTab(1004));
    document.documentElement.style.setProperty('--visibility', 'initial');
    document.getElementById("page-name")?.setAttribute('data-content', t(pageTitle));

    
      let device = getDeviceType()
      console.log("device::",device)
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setIsMobile(true);
    } else setIsMobile(false);
    window?.addEventListener(
      "resize",
      function (event) {
        
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
          setIsMobile(true);
        } else setIsMobile(false);
      },
      true
    );
   
 setTimeout(()=>{
      setShowToggle(true);
      setShowToggle(false);
   },50)
  }, [])
  useEffect(()=>{
    document.documentElement.style.setProperty('--visibility', 'initial');
    document.getElementById("page-name")?.setAttribute('data-content', t(pageTitle));
  },[pageTitle])
  useEffect(() => {
    setShowActiveChatPage(showChat)
  }, [showChat])

  return (
    <div className="d-flex" >
    {showToggle?<Header/>:null}
      <ChatList 
        handleNewChatModal={(e: boolean) => { setOpenNewChat(e) }} 
        openNewChat={openNewChat} 
        setListItemClicked={()=>{setListItemClicked(!listItemClicked)}}
      />
      {
        !isMobile ? <div className='chatwindow col-9' >
          <ChatComponent 
          openNewChat={() => { setOpenNewChat(true) }} 
          mobileScreen={isMobile} 
          activeChatDetails={activeChatDetails} 
          showActiveChatPage={showActiveChatPage} 
          listItemClicked={listItemClicked}/>
          </div> : null
      }
    </div>
  )
}

export default Chat