import config from "../../env.json";
import React, { useState, useEffect } from "react";
import "./PhoneNumberDropdown.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { getPhoneNumberType } from "../../apis/checkPhoneNumberType/landlineLookupApi";
import { useSelector } from "react-redux";

function PhoneNumberDropdown(props) {
      const {
    valueNumber,
    onChangeNumber,
    disableColored,
    errorFunc,
    initialYN,
    frompage,
    numberLandline,
    initiall,
    countryList
  } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState(valueNumber);
  const [error, setError] = useState(false);
  const [initial, setInitial] = useState(true);
  const [isNumberLandline, setIsNumberLandline] = useState(true);
  const [showErr,setShowErr] = useState(false);
  const [countries, setCountries] = useState<any>(
    countryList  
  );
  const [defaultCountry, setdefaultCountry] = useState(
    config.REACT_APP_DEFAULT_COUNTRY
  );
  const [prefix, setPrefix] = useState("+");
  const [minPhone, setMinPhone] = useState(10);

  const inputStyles = {
    border: "1px solid #d3d3d370",
    "borderRadius": "1000px",
    width: "100%",
    padding: "1rem 1rem 1rem 4.5rem",
    "fontSize": "1rem",
    height: "3.5rem",
  };

  const buttonStyles = {
    "borderRadius": "1000px 0px 0px 1000px",
    background: "#f7f4ff",
    padding: "0.2rem 0.8rem",
    border: "none",
  };

  const containerStyles = {
    margin: "0.2rem 0rem",
  };

  useEffect(() => {
    setValue(valueNumber);
    let preFixLength = valueNumber[1] == "1" ? 2 : 3;
    let maxLength = valueNumber[1] == "3" ? 9 : 10;
    if (valueNumber) {
      if (valueNumber.length > 0) {
        setInitial(false);
      }
      setError(
        !isValidPhoneNumber(valueNumber) && (valueNumber.length > preFixLength && valueNumber.length < preFixLength + maxLength)
      );
      errorFunc(
        !isValidPhoneNumber(valueNumber) ||
          valueNumber.length < minPhone ||
          isNumberLandline
      );
      setIsNumberLandline(isNumberLandline);
    } else {
      setdefaultCountry(config.REACT_APP_DEFAULT_COUNTRY)
      setInitial(true);
      //setError(true);
      errorFunc(true);
    }
  }, [valueNumber, isNumberLandline]);

  useEffect(() => {
    console.log(countries);
  }, [countries]);

  useEffect(() => {
    if (initialYN) setInitial(true);
  }, [initialYN]);

  useEffect(() => {
    if (valueNumber) {
      if (isValidPhoneNumber(valueNumber)) {
        setShowErr(false);
        getPhoneNumberType({ phone: valueNumber }, (res) => {
          setShowErr(true);
          if (res.type == "landline") {
            setIsNumberLandline(true);
            numberLandline
              ? numberLandline(true)
              : console.log("function not provided");
          } else {
            setIsNumberLandline(false);
            numberLandline
              ? numberLandline(false)
              : console.log("function not provided");
          }
        });
      } 
      // else {
      //   setIsNumberLandline(false);
      //   // numberLandline(false)
      // }
    }
  }, [isNumberLandline, valueNumber]);

  const handleNumberChange = (e) => {
    let preFixLength = e[1] == "1" ? 2 : 3;
    let maxLength = e[1] == "3" ? 9 : 10;
    setShowErr(false);
    setIsNumberLandline(true);
    console.log(e);
    console.log(error);
    initiall(false);
    if (e) {
      console.log(error, !isValidPhoneNumber(e));
      setError(e.length > preFixLength && e.length < preFixLength + maxLength);
      console.log(error, !isValidPhoneNumber(e));

      errorFunc(!isValidPhoneNumber(e));
    }

    setValue(e);
    setInitial(false);
    onChangeNumber(e);
  };

  return (
    <div className={`${props.class} custom-wrapper`}>
      <PhoneInput
        value={`${value}`}
        country={defaultCountry.toLowerCase()}
        onlyCountries={countries}
        onChange={(e: any) => {
          console.log(value);
          handleNumberChange(`+${e}`);
        }}
        disableDropdown={countries.length == 1}
        disableCountryCode={
          config.REACT_APP_REM_CNTRY_CD_INPUT == "N" ? false : true
        }
        autoFormat={true}
        specialLabel={
          props.frompage === "EditContact" ? "" : t("Phone Number") + "*"
        }
        inputClass={"shadow-none"}
        inputStyle={inputStyles}
        buttonClass={"dropdown-button"}
        buttonStyle={buttonStyles}
        containerStyle={containerStyles}
        countryCodeEditable={false}
        prefix={prefix}
        disabled={disableColored}
        inputProps={{
          name: "phone",
          required: true,
        }}
        placeholder=""
      />
      {error && !initial && !disableColored && (
        <p className="erorrPhone form-error-message">
          {t("Enter a valid phone number")}
        </p>
      )}
      {isNumberLandline && !initial && !error && showErr &&(
        <p className="erorrPhone form-error-message">
          {t("This is a landline number")}
        </p>
      )}
    </div>
  );
}

export default PhoneNumberDropdown;
